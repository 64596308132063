.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 400px;
  height: 400px;
  margin-top: -200px;
  margin-left: -200px;
  /* background-color: black; */
  filter: contrast(30);
}

.blob {
  position: absolute;
  left: 180px;
  top: 180px;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: #c31e18;
  content: "";
  filter: blur(10px);
  transform: translateY(-100px);
}
.blob:nth-child(1) {
  -webkit-animation: spin 2.5s infinite ease-in-out;
          animation: spin 2.5s infinite ease-in-out;
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
}
.blob:nth-child(2) {
  -webkit-animation: spin 2.5s infinite ease-in-out;
          animation: spin 2.5s infinite ease-in-out;
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}
.blob:nth-child(3) {
  -webkit-animation: spin 2.5s infinite ease-in-out;
          animation: spin 2.5s infinite ease-in-out;
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}
.blob:nth-child(4) {
  -webkit-animation: spin 2.5s infinite ease-in-out;
          animation: spin 2.5s infinite ease-in-out;
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}
.blob:nth-child(5) {
  -webkit-animation: spin 2.5s infinite ease-in-out;
          animation: spin 2.5s infinite ease-in-out;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
.blob:nth-child(6) {
  -webkit-animation: spin 2.5s infinite ease-in-out;
          animation: spin 2.5s infinite ease-in-out;
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}
.blob:nth-child(7) {
  -webkit-animation: spin 2.5s infinite ease-in-out;
          animation: spin 2.5s infinite ease-in-out;
  -webkit-animation-delay: 0.7s;
          animation-delay: 0.7s;
}

@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg) translateY(-100px) rotate(0deg);
  }
  70% {
    transform: rotate(360deg) translateY(-100px) rotate(-360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg) translateY(-100px) rotate(0deg);
  }
  70% {
    transform: rotate(360deg) translateY(-100px) rotate(-360deg);
  }
}

/* body {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
} */

.svg-glass #wine-layer-1,
.svg-glass #wine-layer-2,
.svg-glass #wine-layer-3 {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: vawe;
          animation-name: vawe;
}
.svg-glass #wine-layer-1 {
  -webkit-animation-delay: -0.55s;
          animation-delay: -0.55s;
}
.svg-glass #wine-layer-2 {
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}

.col-left,
.col-right {
  padding: 50px;
}

.col-left {
  text-align: center;
  position: absolute;
  top: 30%;
  width: 100%;
}
.col-left span {
  display: block;
  font-size: 18px;
  padding-right: 15px;
  color: #777;
}

@-webkit-keyframes vawe {
  0% {
    d: path("M23.029 48.208c8.136 0 21.202-10.342 21.474-19.297.261-8.558.681-16.233-6.484-15.368-6.16.744-23.987 11.124-31.37 9.427-3.068-.706-4.331-3.917-5.127-3.719-.376.093-.335 3.753-.335 5.948.335 13.905 13.755 23.01 21.842 23.01z");
  }
  50% {
    d: path("M23.029 48.208c8.136 0 21.284-10.343 21.474-19.297.256-11.984.63-10.858-2.765-7.29-5.464 5.743-26.061-6.698-33.251-7.967-6.21-1.095-6.449 3.45-6.965 5.597-.517 2.147-.335 3.753-.335 5.948.335 13.905 13.755 23.01 21.842 23.01z");
  }
  100% {
    d: path("M23.029 48.208c8.136 0 21.202-10.342 21.474-19.297.261-8.558.681-16.233-6.484-15.368-6.16.744-23.987 11.124-31.37 9.427-3.068-.706-4.331-3.917-5.127-3.719-.376.093-.335 3.753-.335 5.948.335 13.905 13.755 23.01 21.842 23.01z");
  }
}

@keyframes vawe {
  0% {
    d: path("M23.029 48.208c8.136 0 21.202-10.342 21.474-19.297.261-8.558.681-16.233-6.484-15.368-6.16.744-23.987 11.124-31.37 9.427-3.068-.706-4.331-3.917-5.127-3.719-.376.093-.335 3.753-.335 5.948.335 13.905 13.755 23.01 21.842 23.01z");
  }
  50% {
    d: path("M23.029 48.208c8.136 0 21.284-10.343 21.474-19.297.256-11.984.63-10.858-2.765-7.29-5.464 5.743-26.061-6.698-33.251-7.967-6.21-1.095-6.449 3.45-6.965 5.597-.517 2.147-.335 3.753-.335 5.948.335 13.905 13.755 23.01 21.842 23.01z");
  }
  100% {
    d: path("M23.029 48.208c8.136 0 21.202-10.342 21.474-19.297.261-8.558.681-16.233-6.484-15.368-6.16.744-23.987 11.124-31.37 9.427-3.068-.706-4.331-3.917-5.127-3.719-.376.093-.335 3.753-.335 5.948.335 13.905 13.755 23.01 21.842 23.01z");
  }
}

